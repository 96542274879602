<template>
  <div>
    <van-field
      v-model="fieldValue"
      is-link
      :required="required"
      readonly
      label="业务类型"
      placeholder="请选择业务类型"
      @click="show = !show"
      :rules="[{ required: required, message: '请选择业务类型' }]"
    />

    <van-popup v-model="show" position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择业务类型"
        :options="options"
        :field-names="fieldNames"
        @close="close"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
  import { ywflTreeService_getYwflTree } from '@/api/psdmsgdgl/service/YwflTreeServiceAPI'

  import { Cascader } from 'vant';
  export default {
    name: 'YwSelblect',
    props: {
      ywfl: {
        type: String,
        default: '5'
      },
      value: {
        type: Array,
        default: () => {
          return []
        }
      },
      required: {
        type: Boolean,
        default: false
      },

      // 需要排除的业务类型
      exclude: { 
        type: String,
        default: ''
      }
    },
    components:{
      [Cascader.name]: Cascader
    },
    computed: {
    },
    data () {
      return {
        jgbm: this.$conf.DEFAULT_JGBM,
        show: false,//Popup是否弹出
        options: [],
        fieldNames: {
          text: 'name',
          value: 'id',
          children: 'children',
        },
        fieldValue: null,//初始选中数据
        cascaderValue: '',
      }
    },
    methods: {
      filterTreeData(list) {
        let arr = [];
        let keys = this.exclude.split(',');
        for(let i = 0; i < list.length; i++) {
          const item = list[i];
          if (keys.indexOf(item.id) == -1) {
            item.children = item.children ? this.filterTreeData(item.children) : null;
            arr.push(item);
          }
        }
        return arr;

      },
      // 获取业务分类树
      fetchYWFLData() {
        const ywfl = this.ywfl;
        const ywlb = null,
              ywzl = null;

        ywflTreeService_getYwflTree(this.jgbm, ywfl, ywlb,ywzl).then(res => {
          let list = res.content.data;

          if (this.exclude) {
            list = this.filterTreeData(res.content.data);
          }
          this.options = [].concat(res.content.data);
          console.log("OPTIONS:", this.options)
        })
      },
      onFinish({value, selectedOptions }) {
        this.show = false;
        this.fieldValue = selectedOptions.map((option) => option.name).join('/');
        this.cascaderValue = value;
        let arr = selectedOptions.map((option) => option.id);
        this.$emit('input', arr);
        this.$emit('confirm', arr, selectedOptions)
      },
      close () {//取消
        this.show = !this.show
        this.$emit('cancel')
      }
    },
    watch: {
      value: {
        handler (newVal) {//监听变化初始赋值
          this.cascaderValue = newVal.length ? newVal[newVal.length - 1].id : ''
        },
        immediate: true
      }
    },
    mounted() {
      this.fetchYWFLData();
    },
  }
</script>