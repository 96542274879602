// WaGen___HASHKEY__4493f8a1_2021-06-03 11:03:02 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
业扩用户基本信息
*/
/* ---
标准CRUD方法：添加记录
*/
export function yhYwJbxx_add(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/add',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwJbxx_update(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/update',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwJbxx_remove(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/remove',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function yhYwJbxx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/selectiveDelete',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function yhYwJbxx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/selectiveUpdate',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function yhYwJbxx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/selectiveAdd',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwJbxx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/fetch',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function yhYwJbxx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/fetchAll',
		method : 'post',
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhYwJbxx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/selectiveGet',
		method : 'post',
		data : {
			param : entity, //YhYwJbxx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function yhYwJbxx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/selectiveGetOne',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function yhYwJbxx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/selectiveCount',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_getYWBH(meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/getYWBH',
		method : 'post',
		data : {
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_getYHBH1(meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/getYHBH1',
		method : 'post',
		data : {
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_getYHBH(jgbm, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/getYHBH',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm //String
			}
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_getFQBM(jgbm, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/getFQBM',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm //String
			}
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_getCZRY(jgbm,yyqd, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/getCZRY',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				yyqd : yyqd //String
			}
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_getDYBM(jgbm,tqbh, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/getDYBM',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				tqbh : tqbh //Integer
			}
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_InsertYhJbxxFromYk(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/InsertYhJbxxFromYk',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_InsertYhJbxxLsFromYk(entity, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/InsertYhJbxxLsFromYk',
		method : 'post',
		data : {
			param : entity //YhYwJbxx
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_insertYhJbxxLsFromYw(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/insertYhJbxxLsFromYw',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_deleteYhJbxxFromYw(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/deleteYhJbxxFromYw',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_insertYhJbxxFromYw(czyh,qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/insertYhJbxxFromYw',
		method : 'post',
		data : {
			param : {
				czyh : czyh, //String
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_insertYhYwJbxxLsFromYw(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/insertYhYwJbxxLsFromYw',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_insertYwJbxxFromYhYyqd(qdrq,ywbh,ywlb,dqzt,ywlx,czyh,ywfl,yyqd,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/insertYwJbxxFromYhYyqd',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				ywlb : ywlb, //String
				dqzt : dqzt, //String
				ywlx : ywlx, //String
				czyh : czyh, //String
				ywfl : ywfl, //String
				yyqd : yyqd, //String
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_updateYhYwJbxxGdrq(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/updateYhYwJbxxGdrq',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_getYhYwJbxxByYwbhJgbmQdrq(ywbh,qdrq, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/getYhYwJbxxByYwbhJgbmQdrq',
		method : 'post',
		data : {
			param : {
				ywbh : ywbh, //Long
				qdrq : qdrq //Date
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_updateYwJbxxCbflFromYk(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/updateYwJbxxCbflFromYk',
		method : 'post',
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_updateYwJbxxCbflFromDnb(fqbm,zzbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/updateYwJbxxCbflFromDnb',
		method : 'post',
		data : {
			param : {
				fqbm : fqbm, //String
				zzbh : zzbh //List<Long>
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_getYkyhcbfl(jgbm,tqbh,yhbh,qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/getYkyhcbfl',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				tqbh : tqbh, //Long
				yhbh : yhbh, //String
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function yhYwJbxx_updateYhkh(yhkh,qdrq,ywbh,yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/updateYhkh',
		method : 'post',
		data : {
			param : {
				yhkh : yhkh, //String
				qdrq : qdrq, //Date
				ywbh : ywbh, //int
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
多记录插入
*/
export function yhYwJbxx_insertList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/insertList',
		method : 'post',
		data : {
			param : list //List<YhYwJbxx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwJbxx_updateList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/updateList',
		method : 'post',
		data : {
			param : list //List<YhYwJbxx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：qdrq : Dateywbh : Longyhbh : String
*/
export function yhYwJbxx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsykgl/dawhschema/YhYwJbxx/deleteList',
		method : 'post',
		data : {
			param : list //List<YhYwJbxx>
		}
	})
}

